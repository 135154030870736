import axios from 'axios';
import { useState } from 'react';
import kartu1 from '../assets/images/kartu-1.jpg';
import kartu2 from '../assets/images/kartu-2.jpg';
import kartu3 from '../assets/images/kartu-3.jpg';
import kartu4 from '../assets/images/kartu-4.jpg';
import kartu5 from '../assets/images/kartu-5.jpg';
import kartu6 from '../assets/images/kartu-6.jpg';
import kartu7 from '../assets/images/kartu-7.jpg';
import logo from '../assets/images/email.png';
import Draggable from 'react-draggable';

function App() {
  const initialState = {
    step: 0,
    first_text: '',
    second_text: '',
    email: '',
    formId: 1994999,
    tagId: 2148663,
    first_x: 0,
    first_y: 0,
    second_x: 0,
    second_y: 0,
    img: 1,
    cetak: '',
    download: false,
  }
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }))
  }
  const { step, first_text, second_text, email, formId, tagId, first_x, first_y, second_x, second_y, img, cetak, download } = state;

  const subscribe = async (apiKey, kartu = '') => {
    const payload = {
      api_key: apiKey,
      email,
      tags: [tagId],
      fields: { kartu: kartu }
    }
    await axios.post(`https://api.convertkit.com/v3/forms/${formId}/subscribe`, payload, {})
      .then(resp => {
        // console.log('resp', resp)
        if (resp.status === 200) {
          setState({ cetak: kartu, step: 3 })
          if (window) {
            window.localStorage.setItem('registered', email);
          }
        }
      })
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    const apiKey = process.env.REACT_APP_CK_KEY ? process.env.REACT_APP_CK_KEY : null;
    await axios.post(`https://api.ervandra.dev/kartu/index.php?first=${encodeURIComponent(first_text)}&second=${encodeURIComponent(second_text)}&email=${encodeURIComponent(email)}&img=${img}`, null)
      .then(resp => {
        console.log('res', resp)
        if (resp.status === 200) {
          if (resp.data.image !== '' && apiKey) {
            if (window) {
              if (window.localStorage.getItem('registered') === email) {
                setState({ cetak: resp.data.image, step: 3, download: true })
              } else {
                subscribe(apiKey, resp.data.image);
              }
            } else {
              subscribe(apiKey, resp.data.image);
            }
          }
        }
      })
  }
  const handleStop1 = (e, position) => {
    setState({ first_x: position.x, first_y: position.y })
  }
  const handleStop2 = (e, position) => {
    setState({ second_x: position.x, second_y: position.y })
  }
  const nextImg = () => {
    if (img < 7) {
      setState({ img: img + 1 })
    }
  }
  const prevImg = () => {
    if (img > 1) {
      setState({ img: img - 1 })
    }
  }
  const urlKartu = [kartu1, kartu2, kartu3, kartu4, kartu5, kartu6, kartu7];
  const resetKartu = () => {
    setState(initialState)
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-4">
          <div className="content">
            <div className="site-header text-center bg-light bg-gradient p-3 shadow-sm" style={{ margin: '0 -0.75rem' }}>
              <div className="d-flex align-items-center justify-content-center">
                <img src={logo} alt="App" className="me-2" style={{ width: '32px' }} />
                <h1 className="fs-6 small text-secondary mb-0">Aplikasi Kartu Ucapan</h1>
              </div>
            </div>
            <div className="main-content p-3 py-5">
              <div className="cara-pakai mb-4 p-2 alert alert-warning bg-gradient rounded-3 shadow-sm" style={{ marginTop: '-24px' }}>
                <p className="small mb-0 text-center">Bingung cara pakai? Lihat tutorialnya di: <a href="https://www.youtube.com/watch?v=gH2GuZGQh3A" target="_blank" className="text-danger" rel="noopener noreferrer">Youtube</a></p>
              </div>
              <div className="steps">
                {step === 0 && (
                  <div className="step" id="step-0">
                    <div className="text-center">
                      <h6 className="text-primary fw-bold mb-3">Silakan Pilih Desain Premium:</h6>
                      <div className="image-carousel mb-3">
                        <div className="kartu" style={{ backgroundImage: `url(${urlKartu[img - 1]})`, backgroundSize: '100%', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }}>
                          <img src={urlKartu[img - 1]} alt={urlKartu[img - 1]} className="opacity-0" />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="prev-btn btn btn-success rounded-pill px-5 " onClick={prevImg}>Prev</div>
                        <div className="next-btn btn btn-success rounded-pill px-5 " onClick={nextImg}>Next</div>
                      </div>

                      <button className="btn btn-primary btn-lg rounded-pill  w-100 shadow-sm" onClick={() => setState({ step: 1 })}>Lanjut &rang;</button>

                    </div>
                  </div>
                )}
                {step === 1 && (
                  <div className="step" id="step-1">
                    <div className="text-center">
                      <h6 className="text-primary">Preview:</h6>
                      <div className="drag-preview preview-container position-relative">
                        <div className="image-carousel mb-3">
                          <div className="kartu" style={{ backgroundImage: `url(${urlKartu[img - 1]})`, backgroundSize: '100%', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }}>
                            <img src={urlKartu[img - 1]} alt={urlKartu[img - 1]} className="opacity-0" />
                          </div>
                        </div>
                        <div className="text-container">
                          {first_text !== '' && (
                            <Draggable
                              handle=".handle"
                              defaultPosition={{ x: first_x, y: first_y }}
                              onStop={handleStop1}>
                              <div className="first text handle">{first_text}</div>
                            </Draggable>
                          )}
                          {second_text !== '' && (
                            <Draggable
                              handle=".handle"
                              defaultPosition={{ x: second_x, y: second_y }}
                              onStop={handleStop2}>
                              <div className="second text handle">{second_text}</div>
                            </Draggable>
                          )}
                        </div>
                      </div>

                      {/* <div className="preview-container position-relative">
                        <img src={demoImg} alt={`desain ${selectedImg}`} />
                        {first_text !== '' && (
                          <div className="first text">{first_text}</div>
                        )}
                        {second_text !== '' && (
                          <div className="second text">{second_text}</div>
                        )}
                      </div> */}
                      <div className="add-text py-3">
                        {/* <label htmlFor="first">Tambahkan Baris pertama</label>
                        <input type="text" maxLength="30" id="first" className="form-control mb-2" value={first_text} onChange={e => setState({ first_text: e.target.value })} /> */}
                        <label htmlFor="second">Tulis pesan anda</label>
                        <input type="text" maxLength="30" id="second" className="form-control" value={second_text} onChange={e => setState({ second_text: e.target.value })} placeholder="contoh: Dari Ervan & Family" />
                      </div>
                      <button className="btn btn-primary rounded-pill shadow-sm" onClick={() => setState({ step: 2 })}>Cetak Gambar</button>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="step" id="step-1">
                    <div className="text-center card bg-light bg-gradient">
                      <form className="card-body text-center" onSubmit={onSubmit}>
                        <h5 className="text-danger mb-3">Kemana Kartu Ini Ingin Di Kirim?</h5>
                        <label htmlFor="email">Email aktif anda</label>
                        <input type="email" id="email" className="form-control mb-3" placeholder="Masukan alamat email anda" required value={email} onChange={e => setState({ email: e.target.value })} />
                        <button className="btn btn-success mb-3 w-100 shadow">Download Kartu Sekarang</button>
                        <p className="mb-0 small text-muted"><em>Kartu berupa Gambar Hi-Resolution akan dikirimkan ke email anda.</em></p>
                      </form>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="step" id="step-3">
                    {download ? (
                      <div className="alert alert-success mb-3 text-center">
                        <p className="mb-0">Silakan klik tombol <strong><u>Download Kartu</u></strong> dibawah. Terima kasih!</p>
                      </div>
                    ) : (
                        <div className="alert alert-success mb-3">
                          <p className="mb-0">Sukses, periksa email anda dan download kartu yang anda buat. Terima kasih!</p>
                        </div>
                      )}
                    <div className="text-center card bg-light bg-gradient">
                      <div className="card-body text-center">
                        <h6>Preview:</h6>
                        {cetak !== '' && (
                          <div className="preview-img mb-3">
                            <img src={cetak} alt="preview-img" />
                          </div>
                        )}
                        {download && (
                          <a href={cetak} className="btn btn-success mb-3 w-100 shadow" target="_blank" rel="noopener noreferrer">Download Kartu</a>
                        )}
                        <button className="btn btn-primary mb-3 w-100 shadow" onClick={resetKartu}>Buat Kartu Lain</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="footer py-2 position-fixed fixed-bottom bg-light bg-gradient border-top">
              <div className="copyright text-center small text-muted">&copy;2021 oleh <a href="https://www.ervandra.dev/?ref=kartuApp" className="text-normal text-secondary" target="_blank" rel="noopener noreferrer">Ervandra</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
