import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/home';
import VIP from './pages/vip';
import WSO from './pages/wso';
import Thankyou from './pages/thankyou';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/thankyou">
          <Thankyou />
        </Route>
        <Route path="/wso">
          <WSO />
        </Route>
        <Route path="/vip">
          <VIP />
        </Route>
        <Route path="/free">
          <VIP />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}