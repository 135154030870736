import { Link } from 'react-router-dom';
import logo from '../assets/images/email.png';

export default function Thankyou() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="site-header text-center bg-light bg-gradient p-3 shadow-sm fixed-top">
            <div className="d-flex align-items-center justify-content-center">
              <img src={logo} alt="App" className="me-2" style={{ width: '32px' }} />
              <h1 className="fs-6 small text-secondary mb-0">Aplikasi Kartu Ucapan</h1>
            </div>
          </div>
          <div className="thanks p-5 px-2 text-center vh-100 d-flex flex-direction-column align-items-center justify-content-center">
            <div>
              <img src={logo} alt="thank you" className="mb-3" width="80" />
              <div className="alert alert-success">
                <h5 className="fw-bold">Terima Kasih</h5>
                <p className="mb-0">Kartu khusus anda sudah dikirim ke email anda lagi, silakan cek <u>inbox email</u> anda.</p>
              </div>
              <Link to="/" className="btn btn-success w-100">Buat Lagi</Link>
            </div>
          </div>
          <div className="footer py-2 position-fixed fixed-bottom bg-light bg-gradient border-top">
            <div className="copyright text-center small text-muted">&copy;2021 oleh <a href="https://www.ervandra.com/?ref=kartuApp" className="text-normal text-secondary" target="_blank" rel="noopener noreferrer">Ervandra Halim</a></div>
          </div>
        </div>
      </div>
    </div>
  )
}